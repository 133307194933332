import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import { router } from "./router";
import { createPinia } from "pinia";

import "@/assets/styles.scss";
import "@/assets/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.css";

// Core Components
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";

import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import Ripple from "primevue/ripple";
import StyleClass from "primevue/styleclass";
import Toast from "primevue/toast";

const NepaliCalendar = defineAsyncComponent(
  () => import("@/components/nepali-date-picker/NepaliCalendar.vue")
);

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(router);

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: ".app-dark",
    },
    cssLayer: {
      name: "primevue",
      order: "tailwind-base, primevue, tailwind-utilities",
    },
  },
  ptOptions: {
    mergeSections: true,
    mergeProps: false,
  },
  ripple: true,
  pt: {
    dataTable: {
      root: {
        class: "p-datatable-sm p-datatable-striped p-datatable-gridlines",
      },
      column: {
        pcColumnFilterButton: {
          style: { display: "none" },
        },
        bodyCell: {
          style: {
            "word-wrap": "break-word",
            "overflow-wrap": "break-word",
            "word-break": "break-word ",
          },
        },
        // pcColumnFilterClearButton: {
        //     style: { display: 'none' },
        // },
      },
    },
    autocomplete: {
      virtualScroller: {
        root: {
          style: { "min-width": "23rem !important" },
        },
      },
    },
    dropdown: {
      wrapper: {
        style: { "min-width": "23rem !important" },
      },
    },
  },
});

app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);
app.directive("tooltip", Tooltip);

app.use(ToastService);
app.use(ConfirmationService);

app.component("NepaliCalendar", NepaliCalendar);
app.component("Toast", Toast);

app.mount("#app");
