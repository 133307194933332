import {useStorage} from '@vueuse/core';
import {defineStore} from 'pinia';
import GlobalSettingsService from "../services/GlobalSettingsService.js";

const loadGlobalSettings = async (orgCode: string, globalSettingsName: string) => {
    let settingsValue = null
    try {
        const globalSettingsService = new GlobalSettingsService();
        let resp: any = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, globalSettingsName);
        if (resp.success) {
            settingsValue = resp.result.settingValue;
        }
    } catch (error) {
        settingsValue = null;
    }
    return settingsValue;
}

export const useGlobalSettingsStore = defineStore({
    id: 'globalSettings',
    state: (): any => ({
        printHeadingOne: useStorage<string | null>('printHeadingOne', null),
        printHeadingTwo: useStorage<string | null>('printHeadingTwo', null),
        paperSizeForWeb: useStorage<string | null>('paperSizeForWeb', null),
        displayOrgLogoOnPaper: useStorage<string | null>('displayOrgLogoOnPaper', null),
        organizationLogo: useStorage<string | null>('displayOrgLogoOnPaper', null),
        termsAndConditions: useStorage<[] | null>('displayOrgLogoOnPaper', null),
        showHsCodeBeforeItemInPrint: useStorage<string | null>('showHsCodeBeforeItemInPrint', null),
        showProductCodeInPrint: useStorage<string | null>('showProductCodeInPrint', null),
        allowNegativeStockInSalesPrint: useStorage<string | null>('allowNegativeStockInSalesPrint', null),
        enableAutoPrinting: useStorage<string | null>('enableAutoPrinting', null),
        abbreviatedTaxInvoiceLimit: useStorage<number | null>('abbreviatedTaxInvoiceLimit', null),
        showDiscountAmountInSalesPrint: useStorage<string | null>('showDiscountAmountInSalesPrint', null),
        recurringCustomerService: useStorage<string | null>('recurringCustomerService', null),
        footerPrintDescription: useStorage<string | null>('footerPrintDescription', null),
    }),
    getters: {
        getHeadingOne: (state): string | null => {
            return state.printHeadingOne === undefined ? null : state.printHeadingOne;
        },
        getHeadingTwo: (state): string | null => {
            return state.printHeadingTwo === undefined ? null : state.printHeadingTwo;
        },
        getPaperSize: (state): string | null => {
            return state.paperSizeForWeb === undefined ? null : state.paperSizeForWeb;
        },
        getPaperToDisplayOrgLogo: (state): string | null => {
            return state.displayOrgLogoOnPaper === undefined ? null : state.displayOrgLogoOnPaper;
        },
        getDisplayOrgLogo: (state): any | null => {
            return (paperSize: any) => paperSize === null || state.displayOrgLogoOnPaper === undefined || state.displayOrgLogoOnPaper === null || state.displayOrgLogoOnPaper === 'NONE'
                ? false : state.displayOrgLogoOnPaper.includes(paperSize);
        },
        getShowHsCodeBeforeItem: (state): boolean | null => {
            return state.showHsCodeBeforeItemInPrint === null ? null : state.showHsCodeBeforeItemInPrint === 'true';
        },
        getShowProductCode: (state): boolean | null => {
            return state.showProductCodeInPrint === null ? null : state.showProductCodeInPrint === 'true';
        },
        getAllowNegativeStock: (state): boolean | null => {
            return state.allowNegativeStockInSalesPrint === null ? null : state.allowNegativeStockInSalesPrint === 'true';
        },
        getEnableAutoPrinting: (state): boolean | null => {
            return state.enableAutoPrinting == null ? null : state.enableAutoPrinting === 'true';
        },
        getAbbreviatedTaxInvoiceLimit: (state): number | null => {
            return state.abbreviatedTaxInvoiceLimit == null ? null : state.abbreviatedTaxInvoiceLimit;
        },
        getShowDiscountAmountInSalesPrint: (state): boolean | null => {
            return state.showDiscountAmountInSalesPrint == null ? null : state.showDiscountAmountInSalesPrint === 'true';
        },
        getRecurringCustomerService: (state): boolean | null => {
            return state.recurringCustomerService == null ? null : state.recurringCustomerService === 'true';
        },
        description: (state): string | null => {
            return state.footerPrintDescription === undefined ? null : state.footerPrintDescription;
        },

    },

    actions: {
        async loadAllGlobalSettings(orgCode: string) {
            console.log("Started Reloading print store ------------")
            this.clearGlobalStore()
            this.loadHeadingOne(orgCode)
            this.loadHeadingTwo(orgCode);
            this.loadPaperSizeForWeb(orgCode)
            this.loadShowHSCodeBeforeItemInPrint(orgCode)
            this.loadShowProductCodeInPrint(orgCode)
            this.loadAllowNegativeStock(orgCode)
            this.loadEnableAutoPrinting(orgCode)
            this.loadAbbreviatedTaxInvoiceLimit(orgCode)
            this.loadShowDiscountAmountInSalesPrint(orgCode)
            this.loadRecurringCustomerService(orgCode)
            this.loadFooterPrintDescription(orgCode)
            console.log("Completed Reloading print store ------------")
        },
        async loadHeadingOne(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "PRINT_HEADING_ONE");
            this.printHeadingOne = settingsValue;
        },
        async loadHeadingTwo(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "PRINT_HEADING_TWO");
            this.printHeadingTwo = settingsValue;
        },
        async loadPaperSizeForWeb(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "PAPER_SIZE_FOR_WEB");
            this.paperSizeForWeb = settingsValue;
        },
        async loadDisplayOrgLogoOnPaper(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "DISPLAY_ORGANIZATION_LOGO_ON_PAPER");
            this.displayOrgLogoOnPaper = settingsValue;
        },

        async loadShowHSCodeBeforeItemInPrint(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "SHOW_HS_CODE_BEFORE_ITEM_IN_PRINT");
            this.showHsCodeBeforeItemInPrint = settingsValue != null ? settingsValue.toLowerCase() : null;
        },
        async loadShowProductCodeInPrint(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "SHOW_PRODUCT_CODE_IN_PRINT");
            this.showProductCodeInPrint = settingsValue != null ? settingsValue.toLowerCase() : null;
        },
        async loadAllowNegativeStock(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "ALLOW_NEGATIVE_STOCK");
            this.allowNegativeStockInSalesPrint = settingsValue != null ? settingsValue.toLowerCase() : null;
        },
        async loadEnableAutoPrinting(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "ENABLE_AUTO_PRINTING");
            this.enableAutoPrinting = settingsValue != null ? settingsValue.toLowerCase() : null;
        },
        async loadAbbreviatedTaxInvoiceLimit(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "ABBREVIATED_TAX_INVOICE_LIMIT");
            this.abbreviatedTaxInvoiceLimit = settingsValue != null ? Number(settingsValue) : 0.0;
        },
        async loadShowDiscountAmountInSalesPrint(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "SHOW_DISCOUNT_IN_SALES_PRINT");
            this.showDiscountAmountInSalesPrint = settingsValue != null ? settingsValue.toLowerCase() : null;
        },
        async loadRecurringCustomerService(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "RECURRING_CUSTOMER_SERVICE");
            this.recurringCustomerService = settingsValue != null ? settingsValue.toLowerCase() : null;
        },
        async loadFooterPrintDescription(orgCode: string) {
            let settingsValue = await loadGlobalSettings(orgCode, "FOOTER_PRINT_DESCRIPTION");
            this.footerPrintDescription = settingsValue;
        },

        async clearGlobalStore() {
            this.printHeadingOne = null;
            this.printHeadingTwo = null;
            this.organizationLogo = null;
            this.paperSizeForWeb = null;
            this.displayOrgLogoOnPaper = null;
            this.termsAndConditions = [];
            this.showHsCodeBeforeItemInPrint = null;
            this.showProductCodeInPrint = null;
            this.allowNegativeStockInSalesPrint = null;
            this.enableAutoPrinting = null;
            this.abbreviatedTaxInvoiceLimit = null
            this.showDiscountAmountInSalesPrint = null
            this.recurringCustomerService = null
            this.footerPrintDescription = null
        },
    }
});
