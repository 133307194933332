import axios from "axios";
import {useAlertStore, useAuthStore} from '@/stores'

const baseUrl = "http://localhost:7070"//"http://localhost:7070" //  "http://103.94.159.197:7071" //server-test
const Api_Path = `${baseUrl}/`;

const httpClient = axios.create({
    // baseURL: Api_Path,
    headers: {
        //Authorization: 'Bearer {token}',
        //timeout: 1000, // indicates, 1000ms i.e. 1 second
        "Content-Type": "application/json",
    }
})

const authInterceptor = (config) => {
    const authStore = useAuthStore();
    const token = authStore.getAccessToken;
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
}

const responseInterceptor = async (response) => {
    if (response.status === 200) {
        const isJson = response.headers?.get('content-type')?.includes('application/json');
        const data = isJson ? await response?.data : null;
        return data;
    }
    return Promise.reject(response);
}
const errorInterceptor = async (error) => {
    const authStore = useAuthStore();
    const alertStore = useAlertStore();

    // check if it's a server error
    if (!error.response) {
        //notify.warn('Network/Server error');
        alertStore.error('**Network/Server error');
        return Promise.reject(error);
    }

    const originalConfig = error.config;
    if (originalConfig.url !== "/Login" && originalConfig.url !== "/login/refresh-access-token" && error.response) {

        if (error.response.status === 404 || error.response.status === 405) {
            alertStore.error("Not a valid request !");
            return Promise.reject(error.data);
        }
        // Not allowed for any actions
        else if (error.response.status === 401 || error.response.status === 417) {
            alertStore.error(error.response.data.message);
            authStore.forceLogout();
            return Promise.reject(error.response.data);
        }
        // Access Token was expired
        else if (error.response.status === 412 && authStore.isLoggedIn) {
            const success = await authStore.refreshAccessToken(authStore.user.username, authStore.org.code, authStore.branch.code, authStore.getRefreshToken);
            if (success) {
                return httpClient(originalConfig);
            }
        } else if (error.response.data.code === 416 || error.response.data.code === 503) {
            alertStore.error(error.response.data.message);
            return Promise.reject(error.response.data);
        } else if (error.response.status === 400 || error.response.status === 403
            || error.response.status === 500) {
            return Promise.reject(error.response.data);
        }
    }
    alertStore.error(error.response.data.message);
    authStore.forceLogout();
    return Promise.reject(error);
}

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
export default httpClient;
