import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import GlobalSettingsService from "../services/GlobalSettingsService.js";
import OrganizationLogoService from "../services/OrganizationLogoService.js";
import TermsConditionsService from "../services/TermsConditionsService.js";


export const usePrintStore = defineStore({
    id: 'print',
    state: () => ({
        printHeadingOne: useStorage('printHeadingOne', null),
        printHeadingTwo: useStorage('printHeadingTwo', null),
        organizationLogo: useStorage('organizationLogo', null),
        paperSizeForWeb: useStorage('paperSizeForWeb', null),
        displayOrgLogoOnPaper: useStorage('displayOrgLogoOnPaper', null),
        termsAndConditions: useStorage('termsAndConditions', []),
        showHsCodeBeforeItemInPrint: useStorage('showHsCodeBeforeItemInPrint', null),
        showProductCodeInPrint: useStorage('showProductCodeInPrint', null),
        showDiscountAmountInSalesPrint: useStorage('showDiscountAmountInSalesPrint', null),
    }),
    getters: {
        headingOne: (state) => {
            return state.printHeadingOne === undefined ? null : state.printHeadingOne;
        },
        headingTwo: (state) => {
            return state.printHeadingTwo === undefined ? null : state.printHeadingTwo;
        },
        paperSize: (state) => {
            return state.paperSizeForWeb === undefined ? null : state.paperSizeForWeb;
        },
        orgLogo: (state) => {
            return state.organizationLogo === undefined ? null : state.organizationLogo;
        },
        paperToDisplayOrgLogo: (state) => {
            return state.displayOrgLogoOnPaper === undefined ? null : state.displayOrgLogoOnPaper;
        },
        displayOrgLogo: (state) => {
            return (paperSize) => paperSize === null || state.displayOrgLogoOnPaper === undefined || state.displayOrgLogoOnPaper === null || state.displayOrgLogoOnPaper === 'NONE'
                ? false : state.displayOrgLogoOnPaper.includes(paperSize);
        },
        getTermsBy: (state) => {
            return (transactionType) => !(state.termsAndConditions ?? null === null) ? []
                : state.termsAndConditions.filter(x => x.transactionType === transactionType)
                    .sort((a, b) => a.orderNo - b.orderNo);
        },
        showHsCodeBeforeItem: (state) => {
            return state.showHsCodeBeforeItemInPrint === null ? null : state.showHsCodeBeforeItemInPrint ==='true';
        },
        showProductCode: (state) => {
            return state.showProductCodeInPrint === null ? null : state.showProductCodeInPrint ==='true';
        },
        showDiscountAmount: (state) => {
            return state.showDiscountAmountInSalesPrint === null ? null : state.showDiscountAmountInSalesPrint ==='true';
        },    },

    actions: {
        async loadPrintSettings(orgId, orgCode) {
            console.log("Started Reloading print store ------------")
            this.clearPrintStore()
            this.loadOrgLogo(orgId)
            this.loadHeadingOne(orgCode)
            this.loadHeadingTwo(orgCode);
            this.loadPaperSizeForWeb(orgCode)
            this.loadTermsAndConditions(orgCode)
            this.loadShowHSCodeBeforeItemInPrint(orgCode)
            this.loadShowProductCodeInPrint(orgCode)
            this.loadShowDiscountAmountInSalesPrint(orgCode)
            console.log("Completed Reloading print store ------------")
        },
        async loadOrgLogo(orgId) {
            try {
                const organizationLogoService = new OrganizationLogoService();
                this.organizationLogo = null;
                const response = await organizationLogoService.getByOrgId(orgId)
                if (response.success) {
                    this.organizationLogo = response.result.base64;
                }
            } catch (error) {
                this.organizationLogo = null;
            }
        },
        async loadHeadingOne(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let headingOneResponse = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, "PRINT_HEADING_ONE");
                this.printHeadingOne = null
                if (headingOneResponse.success) {
                    this.printHeadingOne = headingOneResponse.result.settingValue;
                }
            } catch (error) {
                this.printHeadingOne = null;
            }
        },
        async loadHeadingTwo(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let headingTwoResponse = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, "PRINT_HEADING_TWO");
                this.printHeadingTwo = null;
                if (headingTwoResponse.success) {
                    this.printHeadingTwo = headingTwoResponse.result.settingValue;
                }
            } catch (error) {
                this.printHeadingTwo = null;
            }
        },
        async loadPaperSizeForWeb(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let paperSizeResponse = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, 'PAPER_SIZE_FOR_WEB');
                this.paperSizeForWeb = null;
                if (paperSizeResponse.success) {
                    this.paperSizeForWeb = paperSizeResponse.result.settingValue;
                }
            } catch (error) {
                this.paperSizeForWeb = null;
            }
        },
        async loadDisplayOrgLogoOnPaper(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let response = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, 'DISPLAY_ORGANIZATION_LOGO_ON_PAPER');
                this.displayOrgLogoOnPaper = null;
                if (response.success) {
                    this.displayOrgLogoOnPaper = response.result.settingValue;
                }
            } catch (error) {
                this.displayOrgLogoOnPaper = null;
            }
        },
        async loadTermsAndConditions(orgCode) {
            try {
                const termsConditionsService = new TermsConditionsService();
                const response = await termsConditionsService.getByOrgCode(orgCode);
                this.termsAndConditions = [];
                if (response.success) {
                    this.termsAndConditions = response.result;
                }
            } catch (error) {
                this.termsAndConditions = [];
            }
        },
        async loadShowHSCodeBeforeItemInPrint(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let showHsCodeBeforeItemInPrintResponse = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, "SHOW_HS_CODE_BEFORE_ITEM_IN_PRINT");
                this.showHsCodeBeforeItemInPrint = null;
                if (showHsCodeBeforeItemInPrintResponse.success) {
                    this.showHsCodeBeforeItemInPrint = showHsCodeBeforeItemInPrintResponse.result.settingValue.toLowerCase();
                }
            } catch (error) {
                this.showHsCodeBeforeItemInPrint = null;
            }
        },
        async loadShowProductCodeInPrint(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let showProductCodeInPrintResponse = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, "SHOW_PRODUCT_CODE_IN_PRINT");
                this.showProductCodeInPrint = null
                if (showProductCodeInPrintResponse.success) {
                    this.showProductCodeInPrint = showProductCodeInPrintResponse.result.settingValue.toLowerCase();
                }
            } catch (error) {
                this.showProductCodeInPrint = null;
            }
        },
        async loadShowDiscountAmountInSalesPrint(orgCode) {
            try {
                const globalSettingsService = new GlobalSettingsService();
                let showDiscountAmountInSalesPrintResponse = await globalSettingsService.getOrgGlobalSettingsByName(orgCode, "SHOW_DISCOUNT_IN_SALES_PRINT");
                this.showDiscountAmountInSalesPrint = null
                if (showDiscountAmountInSalesPrintResponse.success) {
                    this.showDiscountAmountInSalesPrint = showDiscountAmountInSalesPrintResponse.result.settingValue.toLowerCase();
                }
            } catch (error) {
                this.showDiscountAmountInSalesPrint = null;
            }
        },
        async clearPrintStore() {
            this.printHeadingOne = null;
            this.printHeadingTwo = null;
            this.organizationLogo = null;
            this.paperSizeForWeb = null;
            this.displayOrgLogoOnPaper = null;
            this.termsAndConditions = [];
            this.showHsCodeBeforeItemInPrint = null;
            this.showProductCodeInPrint = null;
            this.showDiscountAmountInSalesPrint = null;
        },
    }
});
